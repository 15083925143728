import Vue from 'vue'
import store from '@/store'
import router from '@/router'
import VuexRouterSync from 'vuex-router-sync'
import App from './App'
import JQuery from 'jquery';
import ElementUi from 'element-ui';
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm'
import Pagination from 'vue-pagination-2';
import i18n from './i18n'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/ru-RU'
import VueApexCharts from 'vue-apexcharts'
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'
// import ECharts modules manually to reduce bundle size
import {
  CanvasRenderer
} from 'echarts/renderers'
import {
  BarChart
} from 'echarts/charts'
import {
  GridComponent,
  TooltipComponent
} from 'echarts/components'

use([
  CanvasRenderer,
  BarChart,
  GridComponent,
  TooltipComponent
]);



window.$ = JQuery;

import '@/plugins'
import '@/directives'
import '@/locales'
import '@/components'

Vue.config.productionTip = false

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '../public/css/fonts.css';
import '../public/css/main.css';
import '../public/css/media.css';
import '../public/css/header.css'


VuexRouterSync.sync(store, router)
Vue.component('v-chart', ECharts)
Vue.use(VueApexCharts)
Vue.use(BootstrapVue)
Vue.use(ElementUi, {locale})
Vue.component('pagination', Pagination);

/* eslint-disable no-new */
new Vue({
  el: '#app',
  i18n,
  store,
  router,
  ...App
})
