<template>
  <div>
    <nav class="component-navbar main-navbar navbar navbar-expand-lg">
      <div class="container">
        <button @click="showMenu()" type="button" class="navbar-toggler collapsed" id='mobile-btn' data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon">
                        <div class="menu-btn-burger"></div>
                    </span>
        </button>
        <div class="d-flex flex-row align-items-center">
          <a href="#" class="navbar-brand p-0 m-0">
            <img src="/images/EA_logo_final.svg" class="header__logo">
          </a>
          <div class="navbar-brand-desc">
           {{$t('Панель-управления')}}
          </div>
        </div>
        <div v-show="showNavMenu" class="navbar-collapse desktop-menu ">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <router-link tag="a" to="/qabilet" :class="{'nav-item--active': $route.path === '/qabilet' || $route.path === '/'}" class="nav-link">{{$t('Служба Qabilet')}}</router-link >
            </li>
            <li class="nav-item">
              <router-link tag="a" to="/myn-bala" :class="{'nav-item--active': $route.path === '/myn-bala'||  $route.path === '/myn-bala2021'}"  class="nav-link">{{$t('Мың бала')}}</router-link >
            </li>
            <li class="nav-item">
              <router-link tag="a" to="/medal-elbasy"  :class="{'nav-item--active': $route.path === '/medal-elbasy'}" class="nav-link">{{$t('Медаль Елбасы')}}</router-link >
            </li>
            <li class="nav-item">
              <router-link tag="a" to="/birge-oqy"  :class="{'nav-item--active': $route.path === '/birge-oqy'}" class="nav-link">Birge Oqy</router-link >
            </li>
            <li class="nav-item">
              <router-link tag="a" to="/independence-game" :class="{'nav-item--active': $route.path === '/independence-game'}"  class="nav-link">{{$t('ЭраНезависимости.название')}}</router-link >
            </li>
          </ul>
        </div>
      </div>
    </nav>

  </div>
</template>

<script>


export default {
  name: 'Navbar',
  data() {
    return {
      showNavMenu:true,
      logged: false,
      collapsed: true
    }
  },
  watch: {
    '$route' (to, from) {
      if(window.innerWidth<=991){
        this.showNavMenu=false
      }
    }
  },
  mounted() {
    if(window.innerWidth<=991){
      this.showNavMenu=false
    }
    },
  methods: {
    showMenu(){
      this.showNavMenu=!this.showNavMenu
    },
    switchLocale(lang) {
      localStorage.setItem('locale', lang);
      this.$i18n.locale = lang;
      this.$router.go(this.$router.currentRoute)
    },
    clickaway() {
      this.collapsed = true
    },
    // ...mapActions({
    //   'logout': 'logout'
    // })
  },
}
</script>
<style scoped>
@media screen and (max-width: 991px) {
  .nav-item {
    padding-left: 15px;
  }
}
</style>
