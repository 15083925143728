<template>
  <div id="app" class="app">
    <router-view name="sidebar"></router-view>
    <router-view name="header"></router-view>
    <transition name="router" mode="out-in">
      <router-view class="view router-view"></router-view>
    </transition>
    <router-view name="footer"></router-view>
    <router-view name="profilefooter"></router-view>
  </div>
</template>

<script>
import Vue from 'vue';
export default {
  name: 'App',
  beforeCreate() {
    if (!localStorage.getItem('locale')) {
      localStorage.setItem('locale', this.$i18n.locale);
    } else {
      this.$i18n.locale = localStorage.getItem('locale');
    }
    // Vue.http.headers.common['X-Locale'] = this.$i18n.locale;
  },
  mounted() {

  }
}
</script>

<style lang="css">
html,
body {
  height: 100%;
}
.app {
  min-height: 100%;
  overflow-x: hidden;
}

.router-view {
  transition: all .5s ease;
}
.router-enter, .router-leave {
  opacity: 0;
  transform: translate3d(20px, 0, 0);
}
</style>